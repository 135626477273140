import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './AudioPlayer.css'; // Ajouter un fichier CSS pour le style

type Chapter = {
  title: string;
  audio_file_url: string;
};

type AudioPlayerProps = {
  chapters: Chapter[];
};

export const AudioPlayer: React.FC<AudioPlayerProps> = ({ chapters }) => {
  const [currentChapterIndex, setCurrentChapterIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('ended', handleNextChapter);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('ended', handleNextChapter);
      }
    };
  }, [currentChapterIndex]);

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        setIsPlaying(false)
        audioRef.current.pause();
      } else {
        setIsPlaying(true)
        audioRef.current.play();
      }
    }
  };

  const handleNextChapter = () => {
    if (currentChapterIndex < chapters.length - 1) {
      setCurrentChapterIndex((prevIndex) => prevIndex + 1);
    } else {
      setIsPlaying(false);
    }
  };

  const handlePreviousChapter = () => {
    if (currentChapterIndex > 0) {
      setCurrentChapterIndex((prevIndex) => prevIndex - 1);
    } else {
      setIsPlaying(false);
    }
  };

  // Rejouer automatiquement le chapitre suivant quand il change
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.load(); // Charger le nouveau fichier audio
      if (isPlaying) {
        audioRef.current.play(); // Démarrer automatiquement la lecture si elle était déjà en cours
      }
    }
  }, [currentChapterIndex, isPlaying]);

  const currentChapter = chapters[currentChapterIndex];

  return (
    <div className="audio-player">
      <h3>{currentChapter?.title || t('no_chapter')}</h3>
      <audio
        ref={audioRef}
        src={currentChapter?.audio_file_url}
        controls
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      />
      <div className="audio-controls">
        <button
          className="audio-btn ctrl"
          onClick={handlePreviousChapter}
          disabled={currentChapterIndex === 0}
        >
          {t('previous_chapter')}
        </button>
        <button className="audio-btn play" onClick={handlePlayPause}>
          {isPlaying ? t('pause_chapter') : t('play_chapter')}
        </button>
        <button
          className="audio-btn ctrl"
          onClick={handleNextChapter}
          disabled={currentChapterIndex === chapters.length - 1}
        >
          {t('next_chapter')}
        </button>
      </div>
    </div>
  );
};
