import React, { useState } from 'react';
import './Switch.css'; // Ajoutez le CSS pour le style

type SwitchProps = {
  isOn: boolean;
  handleToggle: () => void;
};

const Switch: React.FC<SwitchProps> = ({ isOn, handleToggle }) => {
  return (
    <div className="switch">
      <input
        checked={isOn}
        onChange={handleToggle}
        className="switch-checkbox"
        id={`react-switch-new`}
        type="checkbox"
      />
      <label
        style={{ background: isOn ? '#91CFBC' : '#CCC' }}
        className="switch-label"
        htmlFor={`react-switch-new`}
      >
        <span className="switch-button" />
      </label>
    </div>
  );
};

export default Switch;
