import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher } from '../LanguageSwitcher/LanguageSwitcher';
import './SideMenu.css';
import { logout } from '../../api/auth-users';

export const SideMenu: React.FC = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation(); // Get the current location

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    setIsLoading(true);
    await logout();
    setIsLoading(false);
    window.location.href = '/auth';
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <div className={`side-menu ${isOpen || !isMobile ? 'open' : ''}`}>
        <div className="side-menu-header">
          <div className="logo">
            {t('infinistories')}
          </div>
          <ul>
            <li>
              <Link to="/" className={location.pathname === '/' ? 'active' : ''}>
                {t('menu_home')}
              </Link>
            </li>
            {isAuthenticated && (
              <>
                <li>
                  <Link to="/user-stories" className={location.pathname === '/user-stories' ? 'active' : ''}>
                    {t('menu_my_stories')}
                  </Link>
                </li>
                <li>
                  <Link to="/create-story" className={location.pathname === '/create-story' ? 'active' : ''}>
                    {t('menu_create_story')}
                  </Link>
                </li>
                <li>
                  <Link to="/user-heroes" className={location.pathname === '/user-heroes' ? 'active' : ''}>
                    {t('menu_my_heroes')}
                  </Link>
                </li>
                <li>
                  <Link to="/account" className={location.pathname === '/account' ? 'active' : ''}>
                    {t('menu_my_account')}
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
        <div className="side-menu-footer">
          {isAuthenticated ? (
            <ul>
              <li>
                {isLoading ?
                  <span>{t('logging_out_inprogress')}</span>
                  :
                  <Link to="#" onClick={handleLogout}>{t('logout')}</Link>
                }
              </li>
            </ul>
            ) :
              <ul>
                <li><Link to="/auth" className={location.pathname === '/auth' ? 'active' : ''}>{t('login')}</Link></li>
                <li><Link to="/auth" className={location.pathname === '/auth' ? 'active' : ''}>{t('signup')}</Link></li>
              </ul>
            }
          <LanguageSwitcher />
        </div>
      </div>
      {isMobile && (
        <button className="menu-toggle-button" onClick={toggleMenu}>
          {isOpen ? '✖' : '☰'}
        </button>
      )}
    </div>
  );
};
