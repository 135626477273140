import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { StoryDetailsPage } from './pages/StoryDetailsPage';
import { CreateStoryPage } from './pages/CreateStoryPage';
import { UserStoriesPage } from './pages/UserStoriesPage';
import { UserHeroesPage } from './pages/UserHeroesPage';
import { AuthPage } from './pages/AuthPage';
import { AuthProvider } from './components/AuthContext/AuthContext';
import './styles/App.css'
import { AccountPage } from './pages/AccountPage';
import PiwikPro from '@piwikpro/react-piwik-pro';

PiwikPro.initialize('f652a00f-1223-4511-a12f-a78698b74573', 'https://histoirepourdormir.piwik.pro');

const App = () => (
  <Router>
    <AuthProvider>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/user-stories" element={<UserStoriesPage />} />
        <Route path="/create-story" element={<CreateStoryPage />} />
        <Route path="/user-heroes" element={<UserHeroesPage />} />
        <Route path="/story/:uuid" element={<StoryDetailsPage />} />
        <Route path="/account" element={<AccountPage />} />
      </Routes>
    </AuthProvider>
  </Router>
);

ReactDOM.render(<React.StrictMode><App /></React.StrictMode>, document.getElementById('root'));
