import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDropzone } from 'react-dropzone';
import { AppLayout } from '../components/AppLayout/AppLayout';
import { getUserHeroes, createUserHero, updateUserHero, deleteUserHero } from '../api/heroes';
import { upload } from '../api/resources';
import { useTranslation } from 'react-i18next';
import '../styles/UserHeroesPage.css';
import { HeroCard } from '../components/HeroCard/HeroCard';
import { activitiesOptions, colorsOptions } from '../utils/variables';
import { Loading } from '../components/Loading/Loading';

type Hero = {
  uuid: string;
  name: string;
  age?: number;
  favorite_colors?: string[];
  favorite_activities?: string[];
  avatar_file_url?: string;
  role?: string;
};

type Option = {
  value: string;
  label: string;
};

const roleOptions = (t: (key: string) => string): Option[] => [
  { value: 'mother', label: t('options.roles.mother') },
  { value: 'father', label: t('options.roles.father') },
  { value: 'daughter', label: t('options.roles.daughter') },
  { value: 'son', label: t('options.roles.son') },
  { value: 'animal_dog', label: t('options.roles.dog') },
  { value: 'animal_cat', label: t('options.roles.cat') },
  { value: 'friend', label: t('options.roles.friend') },
  // Ajoutez plus d'options si nécessaire
];

export const UserHeroesPage: React.FC = () => {
  const [loadingHeroes, setLoadingHeroes] = useState<boolean>(true);
  const [heroes, setHeroes] = useState<Hero[]>([]);
  const [newHero, setNewHero] = useState<Hero>({ uuid: '', name: '' });
  const [customRole, setCustomRole] = useState<string>('');
  const [isCustomRole, setIsCustomRole] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const { t } = useTranslation()

  const fetchHeroes = async () => {
    setLoadingHeroes(true)
    const fetchedHeroes = await getUserHeroes();
    setHeroes(fetchedHeroes);
    setLoadingHeroes(false)
  }
  const createHero = async (heroData: any) => {

    heroData = {
      ...heroData,
    }
    await createUserHero(heroData);
  }
  const updateHero = async (heroData: any) => {
    await updateUserHero(heroData);
  }
  const deleteHero = async (heroUuid: string) => {
    await deleteUserHero(heroUuid);
    setHeroes((prevHeroes) => prevHeroes.filter(hero => hero.uuid !== heroUuid));
  }

  // Fetch heroes on mount
  useEffect(() => {
    fetchHeroes();
  }, []);

  const handleSelectChange = (selectedOptions: any, field: keyof Hero) => {
    const values = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
    setNewHero({ ...newHero, [field]: values });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewHero({ ...newHero, [name]: value });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    setAvatarFile(selectedFile || null);
  };

  const handleAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewHero({ ...newHero, age: Number(e.target.value) });
  };

  const onDrop = (acceptedFiles: File[]) => {
    setAvatarFile(acceptedFiles[0]);
  };

  const handleRoleChange = (selectedOption: any) => {
    if (selectedOption) {
      setNewHero({ ...newHero, role: selectedOption.value });
      setIsCustomRole(false);
    }
  };

  const handleCustomRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCustomRole(value);
    setNewHero({ ...newHero, role: value });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': []
    },
    maxFiles: 1,
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Upload avatar if available
    let avatar_url = newHero.avatar_file_url;
    if (avatarFile) {
      const formData = new FormData();
      formData.append('file', avatarFile);
      const uploadResponse = await upload(formData);
      avatar_url = uploadResponse.url;
    }

    const heroData = {
      ...newHero,
      avatar_file_url: avatar_url,
    };

    if (isEditing && newHero.uuid) {
      await updateHero(heroData);
    } else {
      await createHero(heroData);
    }

    setNewHero({ uuid: '', name: '' });
    setAvatarFile(null);
    setIsEditing(false);

    await fetchHeroes()
  };

  const handleEditHero = (hero: Hero) => {
    setNewHero(hero);
    setIsEditing(true);
  };

  const handleDeleteHero = async (uuid: string) => {
    await deleteHero(uuid)
    fetchHeroes()
  };

  return (
    <AppLayout>
      <div className="heroes-page">
        <h2>{t('heroes_list')}</h2>
        {loadingHeroes ?
          <div className="full-center-page"><Loading size={300}></Loading></div>
          : 
            <div className="heroes-list">
              <div className="hero-card">
                <form onSubmit={handleSubmit}>
                  <div>
                    <label>{t('heroes_name')} ({t('required')})</label>
                    <input
                      type="text"
                      name="name"
                      value={newHero.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div>
                    <label>{t('age')}</label>
                    <input
                      type="number"
                      name="age"
                      value={newHero.age || ''}
                      onChange={handleAgeChange}
                    />
                  </div>

                  <div>
                    <label>{t('favorite_colors')}</label>
                    <Select
                      isMulti
                      options={colorsOptions(t)}
                      value={colorsOptions(t).filter(option =>
                        newHero.favorite_colors?.includes(option.value)
                      )}
                      onChange={options => handleSelectChange(options, 'favorite_colors')}
                    />
                  </div>

                  <div>
                    <label>{t('favorite_activities')}</label>
                    <Select
                      isMulti
                      options={activitiesOptions(t)}
                      value={activitiesOptions(t).filter(option =>
                        newHero.favorite_activities?.includes(option.value)
                      )}
                      onChange={options => handleSelectChange(options, 'favorite_activities')}
                    />
                  </div>

                  {/* <div>
                <label>Avatar (optional)</label>
                <div {...getRootProps()} className="dropzone">
                  <input {...getInputProps()} onChange={handleFileChange}/>
                  {avatarFile ? (
                    <p>
                      <section>
                        File details:
                        <ul>
                          <li>Name: {avatarFile.name}</li>
                          <li>Type: {avatarFile.type}</li>
                          <li>Size: {avatarFile.size} bytes</li>
                        </ul>
                      </section>
                    </p>
                  ) : (
                    <p>Drag & drop an image here, or click to select one</p>
                  )}
                </div>
              </div> */}

                  <div>
                    <label>{t('role')}</label>
                    {!isCustomRole ? (
                      <Select
                        options={roleOptions(t)}
                        value={roleOptions(t).filter(option =>
                          newHero.role?.includes(option.value)
                        )}
                        onChange={handleRoleChange}
                        placeholder={t('select_the_role_or_create_your_own')}
                      />
                    ) : (
                      <input
                        type="text"
                        name="role"
                        value={customRole}
                        onChange={handleCustomRoleChange}
                        placeholder={t('enter_a_custom_role')}
                      />
                    )}
                    <button
                      type="button"
                      onClick={() => setIsCustomRole(!isCustomRole)}
                      className="btn"
                    >
                      {isCustomRole ? t('choose_from_list') : t('enter_a_custom_role')}
                    </button>
                  </div>
                  <br></br>
                  <button type="submit" className="submit-btn">{isEditing ? t('update_hero') : t('add_hero')}</button>
                </form>
              </div>
              {heroes.map((hero, index) => (
                <HeroCard hero={hero} key={index} onDelete={handleDeleteHero} onEdit={handleEditHero} />
              ))}
            </div>
          }
      </div>
    </AppLayout>
  );
};
