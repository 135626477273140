import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { AppLayout } from '../components/AppLayout/AppLayout';
import { AudioChapterList } from '../components/AudioChapterList/AudioChapterList';
import { useTranslation } from 'react-i18next';
import { createAudio, getStory, updateStory } from '../api/stories';
import '../styles/StoryDetailsPage.css';
import { getLanguageLabel } from '../utils/variables';
import { useAuth } from '../components/AuthContext/AuthContext';
import Switch from '../components/Switch/Switch';
import { supabase } from '../api/supabase';

export const StoryDetailsPage: React.FC = () => {
    const { userProfile, isAuthenticated } = useAuth();
    const { uuid } = useParams();
    const [story, setStory] = useState<any>(null);
    const [storyHasAudio, setStoryHasAudio] = useState<any>(null);
    const [isPublic, setIsPublic] = useState(true);
    const [loadingStory, setLoadingStory] = useState(true);
    const [loadingChapters, setLoadingChapters] = useState(true);
    const [loadingGenerateAudio, setLoadingGenerateAudio] = useState(false);
    const [totalDuration, setTotalDuration] = useState(0);
    const [textSize, setTextSize] = useState(14); // Gérer la taille du texte
    const [isDarkMode, setIsDarkMode] = useState(false); // Pour le mode sombre
    const { t } = useTranslation();

    const checkIfAllDataIsLoaded = (story: any) => {
        const hasChapters = story?.chapters?.length > 0;
        const hasAudio = story?.chapters?.length > 0 && story.chapters[0].audio_file_url;

        return { hasChapters, hasAudio };
    };

  useEffect(() => {
    const storedTextSize = localStorage.getItem('textSize');
    const storedDarkMode = localStorage.getItem('isDarkMode');

    if (storedTextSize) {
      setTextSize(Number(storedTextSize));
    }
    if (storedDarkMode) {
      setIsDarkMode(storedDarkMode === 'true');
    }
  }, []);

  useEffect(() => {
    const fetchStoryDetails = async () => {
        const fetchedStory = await getStory(uuid!); // API pour récupérer les détails de l'histoire
        setStory(fetchedStory);
        setIsPublic(fetchedStory.is_public);
        setLoadingStory(false);

        const { hasChapters, hasAudio } = checkIfAllDataIsLoaded(fetchedStory);
        if (hasChapters) setLoadingChapters(false);
        setStoryHasAudio(hasAudio);

        if (hasChapters) {
            const td = fetchedStory?.chapters.reduce((total: number, chapter: any) => {
                return total + chapter.duration;
            }, 0);
            setTotalDuration(td);
        }
    };

    fetchStoryDetails();

    // Activer l'écoute des changements sur la table 'stories' via Supabase Realtime
    const storySubscription = supabase
      .channel('stories')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'stories', filter: `uuid=eq.${uuid}` },
          (payload: any) => {
            const newValues = payload.new
            setStory((prevStory: any) => ({
                ...prevStory,
                ...newValues
            }));
        }
    ).subscribe();
    
    const chaptersSubscription = supabase
        .channel('chapters')
        .on(
          'postgres_changes',
          { event: '*', schema: 'public', table: 'chapters', filter: `story_uuid=eq.${uuid}` },
          (payload: any) => {
              const updatedChapter = payload.new;
              setLoadingChapters(false);
    
                setStory((prevStory: any) => {
                    if (!prevStory) return null;
                    
                    if (payload.new.audio_file_url) {
                        setStoryHasAudio(true);   
                    } else {
                        setStoryHasAudio(false);
                    }
        
                    // Mettre à jour la liste des chapitres selon l'événement (INSERT, UPDATE ou DELETE)
                    let updatedChapters = [...prevStory.chapters];
            
                    switch (payload.eventType) {
                        case 'INSERT':
                            updatedChapters = [...updatedChapters, updatedChapter];
                        break;
                        case 'UPDATE':
                            updatedChapters = updatedChapters.map((chapter) =>
                                chapter.uuid === updatedChapter.uuid ? updatedChapter : chapter
                            );
                        break;
                        case 'DELETE':
                            updatedChapters = updatedChapters.filter((chapter) => chapter.uuid !== updatedChapter.uuid);
                        break;
                        default:
                        break;
                    }
        
                    return { ...prevStory, chapters: updatedChapters };
                });
          }
        ).subscribe();

    return () => {
        supabase.removeChannel(storySubscription);
        supabase.removeChannel(chaptersSubscription);
    };
  }, [uuid]);

  const increaseTextSize = () => {
    setTextSize((prev) => {
      const newSize = prev + 2;
      localStorage.setItem('textSize', String(newSize)); 
      return newSize;
    });
  };

  const decreaseTextSize = () => {
    setTextSize((prev) => {
      const newSize = Math.max(prev - 2, 12); 
      localStorage.setItem('textSize', String(newSize)); 
      return newSize;
    });
  };

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem('isDarkMode', String(newMode)); 
      return newMode;
    });
  };

  const handleTogglePublic = async () => {
    const newStatus = !isPublic;
    setIsPublic(newStatus);
    await updateStory(uuid!, { is_public: newStatus });
  };
    
  const handleGenerateAudio = async () => {
    setLoadingGenerateAudio(true)
    await createAudio(uuid!);
    setLoadingGenerateAudio(false)
  };

  return (
    <AppLayout>
      <div className={`story-details-container ${isDarkMode ? 'dark-mode' : ''}`}>
        <div className="story-banner" style={{ backgroundImage: `url(${story?.illustration_file_url})` }}>
          {loadingStory ? <p>{t('loading_story')}</p> : <h1>{t(story?.title)}</h1>}
        </div>
        <div className="story-content">
          <div className="story-text" style={{ fontSize: `${textSize}px` }}>
            <div className="text-size-buttons">
              <button onClick={increaseTextSize}>A+</button>
              <button onClick={decreaseTextSize}>A-</button>
              <button onClick={toggleDarkMode} style={{ fontSize: '20px' }}>🌙</button>
            </div>
            <h2>{t('summary')}</h2>
            <p>{t(story?.summary)}</p>
            {loadingChapters ? (
              <p>{t('loading_chapters')}</p>
            ) : (
              story.chapters.map((chapter: any, index:number) => (
                <div key={chapter.uuid}>
                  <h2>{`${t('chapter')} ${index+1}`}</h2>
                  <p>{chapter.content}</p>
                </div>
              ))
            )}
          </div>
          {loadingChapters ? (
            <div className="story-sidebar">
              <div className="story-informations">
                <p>{t('loading_chapters')}</p>
              </div>
            </div>) : (
            <div className="story-sidebar">
                {isAuthenticated ? (
                    storyHasAudio ?
                        <AudioChapterList story={story} loadingChapters={loadingChapters}></AudioChapterList>
                                      : <div className="story-informations">
                                          <button type="submit" className="submit-btn" disabled={loadingGenerateAudio} onClick={handleGenerateAudio}>
                            {loadingGenerateAudio ? t('loader_generating_audio') : t('generate_audio')}
                        </button></div>
              ) : (
                <div className="story-chapters-sidebar">
                  <h2>{t('listen_story')}</h2>
                  {loadingChapters ? (
                    <p>{t('loading_chapters')}</p>
                  ) : (
                    <Link to="/auth">{t('connect_to_have_audio_access')}</Link>
                  )}
                </div>
              )}
              <div className="story-informations">
                <h2>{t('story_information')}</h2>
                <ul>
                  <li><strong>{t('author')}:</strong> {story?.profiles?.username ? story?.profiles?.username : t('unknown_author')}</li>
                  <li><strong>{t('heroes_principal')}:</strong> {Array.isArray(story?.story_heroes) && story?.story_heroes.filter((item: any) => item.is_principal).map((item: any) => item.heroes.name).join(', ') || t('no_heroes_values')}</li>
                  <li><strong>{t('heroes_secondary')}:</strong> {Array.isArray(story?.story_heroes) && story?.story_heroes.filter((item: any) => !item.is_principal).map((item: any) => item.heroes.name).join(', ') || t('no_heroes_values')}</li>
                  <li><strong>{t('creation_date')}:</strong> {new Date(story?.created_at).toLocaleDateString() || t('unknown_date')}</li>
                  <li><strong>{t('themes')}:</strong> {story?.themes ? t(`options.themes.${story?.themes}`) : t('no_themes')}</li>
                  <li><strong>{t('moral_values')}:</strong> {Array.isArray(story?.moral_values) && story?.moral_values.map((item: string) => t(`options.moralValues.${item}`)).join(', ') || t('no_moral_values')}</li>
                  <li><strong>{t('educational_elements')}:</strong> {Array.isArray(story?.educational_elements) && story?.educational_elements.map((item: string) => t(`options.educationalElements.${item}`)).join(', ') || t('no_educational_elements')}</li>
                  <li><strong>{t('duration')}:</strong> {totalDuration || t('unknown_duration')} {t('minutes')}</li>
                  <li><strong>{t('tone')}:</strong> {story?.tone ? t(`options.tones.${story?.tone}`) : t('no_tone')}</li>
                  <li><strong>{t('language')}:</strong> {getLanguageLabel(story?.language) || t('unknown_language')}</li>
                  <li><strong>{t('original_request')}:</strong> {story?.original_prompt}</li>
                </ul>
              </div>
              {userProfile?.uuid === story.profiles.uuid &&
                <div className="story-informations">
                  <h2>{t('manage_your_stories')}</h2>
                  <ul>
                    <li>
                      <strong>{t('status')}: </strong>
                      <Switch isOn={isPublic} handleToggle={handleTogglePublic} />
                      <span>{isPublic ? t('public_story') : t('private_story')}</span>
                    </li>
                  </ul>
                </div>
              }
              <div className="story-informations">
                <ul>
                  <li><strong>{t('listen_count')}:</strong> {story?.listen_count || 0}</li>
                  <li><strong>{t('chapters_count')}:</strong> {story?.chapters.length || 0}</li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </AppLayout>
  );
};
