import React, { useState } from 'react';
import { AppLayout } from '../components/AppLayout/AppLayout';
import { useTranslation } from 'react-i18next';
import '../styles/AccountPage.css';
import { useAuth } from '../components/AuthContext/AuthContext';
import { updateUserProfile } from '../api/auth-users';

export const AccountPage: React.FC = () => {
  const { userProfile, setUserProfile } = useAuth();
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [newUsername, setNewUsername] = useState(userProfile?.username || '');

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setNewUsername(userProfile?.username || ''); // Réinitialiser le champ si annulé
  };

  const handleSaveClick = async () => {
    if (newUsername.trim() !== '') {
      try {
        const updatedProfile = await updateUserProfile({ username: newUsername });
        setUserProfile(updatedProfile); // Met à jour le profil dans le contexte
        setIsEditing(false); // Fermer le mode édition
      } catch (error) {
        console.error('Error updating username:', error);
      }
    }
  };

  return (
    <AppLayout>
      <div className="account-page-container">
        <div className="account-banner" />
        <div className="account-content">
          <h1>{t('account_info')}</h1>
          <ul>
            <li>{t('email')}: {userProfile?.email}</li>
            <li>
              {t('username')}:
              {isEditing ? (
                <>
                  <input
                    type="text"
                    value={newUsername}
                    onChange={(e) => setNewUsername(e.target.value)}
                    className="edit-username-input"
                  />
                  <div className="edit-buttons">
                    <button onClick={handleSaveClick} className="save-btn">{t('save')}</button>
                    <button onClick={handleCancelClick} className="cancel-btn">{t('cancel')}</button>
                  </div>
                </>
              ) : (
                <>
                  <span>{userProfile?.username}</span>
                  <button onClick={handleEditClick} className="edit-btn">{t('edit')}</button>
                </>
              )}
            </li>
          </ul>
        </div>
        <div>
          
        </div>

      </div>
    </AppLayout>
  );
};
