import axios from "axios";

export const upload = async (formData: any) => {
  try {
    const API_BASE_URL = process.env.REACT_APP_API_URL;
    const API_KEY = process.env.REACT_APP_API_KEY;

    const axiosInstance = axios.create({
      baseURL: API_BASE_URL,
      headers: {
        "x-api-key": API_KEY,
        "content-type": "multipart/form-data",
      },
    });

    const response = await axiosInstance.post(`/upload-resource`, formData);
    return response.data.url;
  } catch (error) {
    throw new Error(`Error uploading resource: ${error}`);
  }
};
