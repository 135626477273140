// Fonction pour décoder un JWT sans le vérifier
const decodeJWT = (token: string) => {
  const payloadBase64 = token.split(".")[1]; // Extraire la partie payload du token
  const decodedPayload = JSON.parse(atob(payloadBase64)); // Décoder le payload en JSON
  return decodedPayload;
};

// Fonction pour vérifier si le token est expiré ou bientôt expiré
const isTokenExpiring = (token: string, bufferTimeInSeconds = 300) => {
  const decoded = decodeJWT(token);

  // Vérifier s'il y a une date d'expiration dans le token
  if (!decoded || !decoded.exp) {
    console.error("Le token n'a pas de champ 'exp'.");
    return true;
  }

  const currentTimeInSeconds = Math.floor(Date.now() / 1000); // Convertir l'heure actuelle en secondes
  const expTimeInSeconds = decoded.exp;

  // Si le temps actuel est supérieur à la date d'expiration ou proche de la fin, on retourne true
  return expTimeInSeconds - currentTimeInSeconds < bufferTimeInSeconds;
};

export { isTokenExpiring };
