import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { createStory, createChapters, createIllustration, createAudio, createTitleAndSummary } from '../api/stories';
import { getUserHeroes } from '../api/heroes';
import { AppLayout } from '../components/AppLayout/AppLayout';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { educationalElementsOptions, languagesOptions, moralValuesOptions, themesOptions, tonesOptions } from '../utils/variables';
import '../styles/CreateStoryPage.css';

type Hero = {
  uuid: string;
  name: string;
  age?: number;
  favorite_colors?: string[];
  favorite_activities?: string[];
  avatar_file_url?: string;
};

type StoryData = {
  prompt: string;
  heroes: Hero[];
  secondary_heroes: Hero[]; // Ajout des personnages secondaires
  tone?: string;
  themes?: string;
  educational_elements?: string[];
  moral_values?: string[];
  duration: number;
  language: string;
};

export const CreateStoryPage: React.FC = () => {
  const [heroes, setHeroes] = useState<Hero[]>([]);
  const [showExtraOptions, setShowExtraOptions] = useState(false);
  const [storyData, setStoryData] = useState<StoryData>({
    prompt: localStorage.getItem('story-original-request') ?? '',
    duration: 5,
    language: 'fr',
    heroes: [],
    secondary_heroes: [] // Initialisation des personnages secondaires
  });
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [loadingHeroes, setLoadingHeroes] = useState(true);
  const heroUuidFromUrl = new URLSearchParams(location.search).get('hero_uuid');
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchHeroes() {
      setLoadingHeroes(true);
      const fetchedHeroes = await getUserHeroes();
      setHeroes(fetchedHeroes);

      if (heroUuidFromUrl) {
        const preselectedHero = fetchedHeroes.find((hero: Hero) => hero.uuid === heroUuidFromUrl);
        if (preselectedHero) {
          setStoryData(prevData => ({
            ...prevData,
            heroes: [preselectedHero]
          }));
        }
      }
      setLoadingHeroes(false);
    }
    fetchHeroes();
  }, [heroUuidFromUrl]);

  // Génération des options pour Select
  const heroOptions = heroes.map(hero => ({
    value: hero.uuid,
    label: hero.name,
  }));

  // Filtrage des options pour les personnages secondaires
  const secondaryHeroOptions = heroes.filter(hero =>
    !storyData.heroes.some(selectedHero => selectedHero.uuid === hero.uuid)
  ).map(hero => ({
    value: hero.uuid,
    label: hero.name,
  }));

  // Fonction pour créer une histoire
  const handleCreateStory = async (storyData: any) => {
    setLoading(true);

    try {
      const createStoryResponse = await createStory(storyData);
      const storyUUID = createStoryResponse.story_uuid;

      localStorage.removeItem('story-original-request');

      navigate(`/story/${storyUUID}`);

      await createChapters(storyUUID, storyData);

      await Promise.all([
        createTitleAndSummary(storyUUID),
        createIllustration(storyUUID),
        //createAudio(storyUUID),
      ]);

    } catch (error) {
      console.error('Error creating story or subsequent API calls', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setStoryData({ ...storyData, [name]: value });
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    localStorage.setItem('story-original-request', value);
    setStoryData(prevData => ({ ...prevData, prompt: value }));
  };

  const handleHeroesSelectChange = (selectedOptions: any) => {
    const selectedHeroes = selectedOptions.map((option: any) => heroes.find(h => h.uuid === option.value));
    setStoryData(prevData => ({ ...prevData, heroes: selectedHeroes }));
  };

  const handleSecondaryHeroesSelectChange = (selectedOptions: any) => {
    const selectedHeroes = selectedOptions.map((option: any) => heroes.find(h => h.uuid === option.value));
    setStoryData(prevData => ({ ...prevData, secondary_heroes: selectedHeroes }));
  };

  const handleMultiSelectChange = (selectedOptions: any, field: keyof StoryData) => {
    const values = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
    setStoryData({ ...storyData, [field]: values });
  };

  const handleSelectChange = (selectedOption: any, field: keyof StoryData) => {
    setStoryData({ ...storyData, [field]: selectedOption.value });
  };

  const handleToggleOptions = () => {
    setShowExtraOptions(!showExtraOptions);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    handleCreateStory(storyData);
  };

  return (
    <AppLayout>
      <div className="story-details-container">
        <div className="story-banner" style={{ backgroundImage: `url('./assets/background.webp`}}>
          <h1>{t('create_story')}</h1>
        </div>
        <form className="create-story-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="storyText">{t('create_story_write_prompt_idea_label')}</label>
            <textarea
              id="storyText"
              rows={6}
              value={storyData.prompt}
              onChange={handleTextAreaChange}
              placeholder={t('create_story_write_prompt_idea_placeholder')}
              required
            />
          </div>

          {loadingHeroes ? (
            <p>{t('loading_heroes')}</p>
          ) : (
            <>
              {heroes.length > 0 ? (
                  <>
                    <div className="select-heroes-group">
                      <div className="form-group">
                        <label htmlFor="heroes">{t('select_heroes_label')}</label>
                        <Select
                          id="heroes"
                          isMulti
                          options={heroOptions}
                          onChange={handleHeroesSelectChange}
                          value={heroOptions.filter(option =>
                            storyData.heroes.some(hero => hero.uuid === option.value)
                          )}
                          className="select-heroes"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="secondary_heroes">{t('select_secondary_heroes_label')}</label>
                        <Select
                          id="secondary_heroes"
                          isMulti
                          options={secondaryHeroOptions}
                          onChange={handleSecondaryHeroesSelectChange}
                          value={secondaryHeroOptions.filter(option =>
                            storyData.secondary_heroes.some(hero => hero.uuid === option.value)
                          )}
                          className="select-heroes"
                        />
                      </div>
                    </div>
                </>
              ) : (
                <Link to="/user-heroes">{t('create_your_heroes')}</Link>
              )}
            </>
          )}

          <button type="button" className="btn" onClick={handleToggleOptions}>
            {showExtraOptions ? t('create_story_hide_extra_options') : t('create_story_show_extra_options')}
          </button>

          {showExtraOptions && (
            <div className="extra-options">
              <div>
                <label>{t('tone')}</label>
                <Select
                  options={tonesOptions(t)}
                  onChange={(option) => handleSelectChange(option, 'tone')}
                />
              </div>

              <div>
                <label>{t('themes')}</label>
                <Select
                  options={themesOptions(t)}
                  onChange={(option) => handleSelectChange(option, 'themes')}
                />
              </div>

              <div>
                <label>{t('educational_elements')}</label>
                <Select
                  isMulti
                  options={educationalElementsOptions(t)}
                  onChange={(option) => handleMultiSelectChange(option, 'educational_elements')}
                />
              </div>

              <div>
                <label>{t('moral_values')}</label>
                <Select
                  isMulti
                  options={moralValuesOptions(t)}
                  onChange={(option) => handleMultiSelectChange(option, 'moral_values')}
                />
              </div>

              <div>
                <label>{t('create_story_duration_in_minutes')}</label>
                <input
                  type="number"
                  name="duration"
                  value={storyData.duration}
                  onChange={handleInputChange}
                  min={1}
                  max={10}
                />
              </div>

              <div>
                <label>{t('language')}</label>
                <Select
                  options={languagesOptions}
                  defaultValue={languagesOptions[0]}
                  onChange={(option) => handleSelectChange(option, 'language')}
                />
              </div>
            </div>
          )}
          
          <button type="submit" className="submit-btn" disabled={loading}>
            {loading ? t('loader_creating') : t('create')}
          </button>
        </form>
      </div>
    </AppLayout>
  );
};
