import { axiosInstanceWithUserAuth, axiosInstance } from "./axios-instance";

export const getAllStories = async () => {
  try {
    const response = await axiosInstance.post(`/stories`, {
      action: "GET_ALL_STORIES",
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching stories: ${error}`);
  }
};

export const getUserStories = async () => {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/stories`, {
      action: "GET_USER_STORIES",
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching user stories: ${error}`);
  }
};

export const createStory = async (storyData: any) => {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/stories`, {
      action: "CREATE_STORY",
      storyRequest: storyData,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error creating story: ${error}`);
  }
};

export const createChapters = async (storyUuid: string, storyData: any) => {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/stories`, {
      action: "CREATE_CHAPTERS",
      storyRequest: storyData,
      storyUuid,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error creating chapters: ${error}`);
  }
};

export const createIllustration = async (storyUuid: string) => {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/stories`, {
      action: "CREATE_ILLUSTRATION",
      storyUuid,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error creating illustration: ${error}`);
  }
};

export const createAudio = async (storyUuid: string) => {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/stories`, {
      action: "CREATE_AUDIO",
      storyUuid,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error creating audio: ${error}`);
  }
};

export const createTitleAndSummary = async (storyUuid: string) => {
  try {
    const response = await axiosInstanceWithUserAuth.post(`/stories`, {
      action: "CREATE_TITLE_AND_SUMMARY",
      storyUuid,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error creating title and summary: ${error}`);
  }
};

export const getStory = async (storyUuid: string) => {
  try {
    const response = await axiosInstance.post(`/stories`, {
      action: "GET_STORY",
      storyUuid,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching story by ID: ${error}`);
  }
};

export const updateStory = async (storyUuid: string, data: any) => {
  try {
    const response = await axiosInstance.post(`/stories`, {
      action: "UPDATE_STORY",
      storyUuid,
      data,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching story by ID: ${error}`);
  }
};
