import React from 'react';
import { useTranslation } from 'react-i18next';
import { AudioPlayer } from '../AudioPlayer/AudioPlayer';

export const AudioChapterList: React.FC<{ story: any; loadingChapters: boolean }> = ({ story, loadingChapters }) => {
  const { t } = useTranslation()
  
  return (
    <div className="story-chapters-sidebar">
      <h2>{t('listen_story')}</h2>
      {loadingChapters ? (
        <p>{t('loading_chapters')}</p>
      ) : (
        <AudioPlayer chapters={story.chapters} />
      )}
    </div>
  );
};
