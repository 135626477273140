import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import Switch from '../Switch/Switch';
import { languagesOptions, tonesOptions, themesOptions, moralValuesOptions, educationalElementsOptions } from '../../utils/variables';
import './FilterPanel.css'

type FilterOption = {
  value: string;
  label: string | JSX.Element;
};

type FilterPanelProps = {
  heroes: FilterOption[];
  maxDuration: number | undefined;
  isOnlyUserStories: boolean;
  onLanguageChange: (selectedOption: FilterOption | null) => void;
  onToneChange: (selectedOption: FilterOption | null) => void;
  onThemeChange: (selectedOption: FilterOption | null) => void;
  onMoralValueChange: (selectedOption: FilterOption | null) => void;
  onEducationalElementChange: (selectedOption: FilterOption | null) => void;
  onHeroChange: (selectedOption: FilterOption | null) => void;
  onDurationChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onToggleUserStories: () => void;
  showLanguageFilter?: boolean;
  showHeroFilter?: boolean;
  showDurationFilter?: boolean;
  showUserStoriesToggle?: boolean;
  defaultLanguage: string;
  showToneFilter: boolean;
  showThemeFilter: boolean;
  showMoralValueFilter: boolean;
  showEducationalElementFilter: boolean;
};

export const FilterPanel: React.FC<FilterPanelProps> = ({
  heroes,
  maxDuration,
  isOnlyUserStories,
  onLanguageChange,
  onToneChange,
  onThemeChange,
  onMoralValueChange,
  onEducationalElementChange,
  onHeroChange,
  onDurationChange,
  onToggleUserStories,
  showLanguageFilter = true,
  showHeroFilter = true,
  showDurationFilter = true,
  showUserStoriesToggle = true,
  defaultLanguage,
  showToneFilter = true,
  showThemeFilter = true,
  showMoralValueFilter = true,
  showEducationalElementFilter = true
}) => {
  const { t } = useTranslation();

  return (
    <div className="filter-container">
      <h2>{t('filters')}</h2>
      <div className="filters">
        {showLanguageFilter && (
          <div className="filter-item">
            <label>{t('language')} :</label>
            <Select
              className="select-filters"
              options={languagesOptions}
              onChange={onLanguageChange}
              placeholder={t('select_language')}
              defaultValue={languagesOptions.find((option: any) => option.value === defaultLanguage)}
              isClearable
            />
          </div>
        )}
        {showToneFilter && (
          <div className="filter-item">
            <label>{t('tone')} :</label>
            <Select
              className="select-filters"
              options={tonesOptions(t)}
              onChange={onToneChange}
              placeholder={t('select_tone')}
              isClearable
            />
          </div>
        )}
        {showThemeFilter && (
          <div className="filter-item">
            <label>{t('theme')} :</label>
            <Select
              className="select-filters"
              options={themesOptions(t)}
              onChange={onThemeChange}
              placeholder={t('select_theme')}
              isClearable
            />
          </div>
        )}
        {showMoralValueFilter && (
          <div className="filter-item">
            <label>{t('moral_value')} :</label>
            <Select
              className="select-filters"
              options={moralValuesOptions(t)}
              onChange={onMoralValueChange}
              placeholder={t('select_moral_value')}
              isClearable
            />
          </div>
        )}
        {showEducationalElementFilter && (
          <div className="filter-item">
            <label>{t('educational_element')} :</label>
            <Select
              className="select-filters"
              options={educationalElementsOptions(t)}
              onChange={onEducationalElementChange}
              placeholder={t('select_educational_element')}
              isClearable
            />
          </div>
        )}
        {showHeroFilter && (
          <div className="filter-item">
            <label>{t('heroes')} :</label>
            <Select
              className="select-filters"
              options={heroes}
              onChange={onHeroChange}
              placeholder={t('select_hero')}
              isClearable
            />
          </div>
        )}
        {showDurationFilter && (
          <div className="filter-item">
            <label>{t('duration_max_in_minute')} :</label>
            <input
              type="number"
              value={maxDuration || ''}
              onChange={onDurationChange}
              placeholder={t('duration_max')}
              min="1"
            />
          </div>
        )}
        {showUserStoriesToggle && (
          <div className="filter-item">
            <label>{t('only_my_stories')} :</label>
            <Switch isOn={isOnlyUserStories} handleToggle={onToggleUserStories} />
          </div>
        )}
      </div>
    </div>
  );
};
