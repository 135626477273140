import React from 'react';
import { useTranslation } from 'react-i18next';
import './HeroCard.css';
import { useNavigate } from 'react-router-dom';

type HeroCardProps = {
  hero: any;
  onEdit: (hero: any) => void;
  onDelete: (heroId: string) => void;
};

export const HeroCard: React.FC<HeroCardProps> = ({ hero, onEdit, onDelete }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCreateWith = () => {
    navigate(`/create-story?hero_uuid=${hero.uuid}`)
  };

  const handleEdit = () => {
    onEdit(hero);
  };

  const handleDelete = () => {
    onDelete(hero.uuid);
  };

  return (
    <div className="hero-card">
      {hero.avatar_file_url && (
        <img
          src={hero.avatar_file_url}
          alt={`Avatar de ${hero.name}`}
          className="hero-illustration"
        />
      )}
      <div className="hero-card-content">
        <h2 className="hero-card-title">{hero.name}</h2>
        <p className="hero-card-summary">{t('age')}: {hero.age}</p>
        <p className="hero-card-summary">{t('role')}: {hero.role && t(`options.roles.${hero.role}`)}</p>
        <p className="hero-card-summary">
          {t('favorite_colors')}: {Array.isArray(hero.favorite_colors) && hero.favorite_colors
            .map((color: string) => t(`options.colors.${color}`))
            .join(', ')}
        </p>
        <p className="hero-card-summary">
          {t('favorite_activities')}: {Array.isArray(hero.favorite_activities) && hero.favorite_activities
            .map((activity: string) => t(`options.activities.${activity}`))
            .join(', ')}
        </p>   
      </div>  
      <div className="hero-card-actions">
        <button onClick={handleCreateWith} className="create-with-button">
        🖊️ {t('create_story')}
        </button>
        <button onClick={handleEdit} className="edit-button">
        🖊️ {t('update')}
        </button>
        <button onClick={handleDelete} className="delete-button">
        🗑️ {t('delete')}
        </button>
      </div>
    </div>
  );
};
