import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getUserStories } from '../api/stories';
import { StoryCard } from '../components/StoryCard/StoryCard';
import { AppLayout } from '../components/AppLayout/AppLayout';
import { FilterPanel } from '../components/FilterPanel/FilterPanel';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import '../styles/HomePage.css';
import { Loading } from '../components/Loading/Loading';

type Hero = {
  uuid: string;
  name: string;
};

export const UserStoriesPage: React.FC = () => {
  const [stories, setStories] = useState<any[]>([]);
  const [loadingStories, setLoadingStories] = useState<any>(true);
  const [filteredStories, setFilteredStories] = useState<any[]>([]);
  const [languages, setLanguages] = useState<string[]>([]);
  const [heroes, setHeroes] = useState<Hero[]>([]);
  const [selectedHero, setSelectedHero] = useState<Hero | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null);
  const [selectedTone, setSelectedTone] = useState<string | null>();
  const [selectedTheme, setSelectedTheme] = useState<string | null>();
  const [selectedMoralValue, setSelectedMoralValue] = useState<string | null>();
  const [selectedEducationalElement, setSelectedEducationalElement] = useState<string | null>();
  const [maxDuration, setMaxDuration] = useState<number | undefined>();
  const { t } = useTranslation();
  const [isOnlyUserStories, setIsOn] = useState(false);

  const toggleSwitch = () => setIsOn(!isOnlyUserStories);

  useEffect(() => {
    const fetchStories = async () => {
      setLoadingStories(true)
      const fetchedStories = await getUserStories();
      setStories(fetchedStories);
      setFilteredStories(fetchedStories);

      // Extract languages and heroes from stories
      const languagesSet = new Set<string>();
      const heroesList: Hero[] = [];
      fetchedStories.forEach((story: any) => {
        if (story.language) {
          languagesSet.add(story.language);
        }
        if (story.heroes) {
          story.heroes.forEach((hero: Hero) => {
            if (!heroesList.find((h) => h.uuid === hero.uuid)) {
              heroesList.push(hero);
            }
          });
        }
      });
      setLanguages(Array.from(languagesSet));
      setHeroes(heroesList);
      setLoadingStories(false);
    };
    fetchStories();
  }, []);

  const handleLanguageChange = (selectedOption: any) => {
    setSelectedLanguage(selectedOption?.value || null);
  };

  const handleToneChange = (selectedOption: any) => {
    setSelectedTone(selectedOption?.value || null);
  };

  const handleThemeChange = (selectedOption: any) => {
    setSelectedTheme(selectedOption?.value || null);
  };

  const handleMoralValueChange = (selectedOption: any) => {
    setSelectedMoralValue(selectedOption?.value || null);
  };

  const handleEducationalElementChange = (selectedOption: any) => {
    setSelectedEducationalElement(selectedOption?.value || null);
  };

  const handleHeroChange = (selectedOption: any) => {
    const selected = heroes.find((h) => h.uuid === selectedOption?.value);
    setSelectedHero(selected || null);
  };

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? parseInt(e.target.value, 10) : undefined;
    setMaxDuration(value);
  };

  useEffect(() => {
    let filtered = [...stories];

    if (selectedLanguage) {
      filtered = filtered.filter((story) => story.language === selectedLanguage);
    }

    if (selectedTone) {
      filtered = filtered.filter((story) => story.tone === selectedTone);
    }

    if (selectedTheme) {
      filtered = filtered.filter((story) => story.theme === selectedTheme);
    }

    if (selectedMoralValue) {
      filtered = filtered.filter((story) => story.moralValues.includes(selectedMoralValue));
    }

    if (selectedEducationalElement) {
      filtered = filtered.filter((story) => story.educationalElement === selectedEducationalElement);
    }

    if (selectedHero) {
      filtered = filtered.filter((story) =>
        story.heroes?.some((hero: Hero) => hero.uuid === selectedHero.uuid)
      );
    }

    if (maxDuration !== undefined) {
      filtered = filtered.filter((story) => {
        const totalDuration = story.chapters?.reduce((acc: number, chapter: any) => acc + chapter.duration, 0);
        return totalDuration <= maxDuration;
      });
    }

    setFilteredStories(filtered);
  }, [selectedLanguage, selectedHero, maxDuration, stories, /*isOnlyUserStories*/]);

  const languageOptions = languages.map((language) => ({ value: language, label: language }));
  const heroOptions = heroes.map((hero) => ({ value: hero.uuid, label: hero.name }));

  return (
    <AppLayout>
      <FilterPanel
        heroes={heroOptions}
        maxDuration={maxDuration}
        isOnlyUserStories={isOnlyUserStories}
        onLanguageChange={handleLanguageChange}
        onToneChange={handleToneChange}
        onThemeChange={handleThemeChange}
        onMoralValueChange={handleMoralValueChange}
        onEducationalElementChange={handleEducationalElementChange}
        onHeroChange={handleHeroChange}
        onDurationChange={handleDurationChange}
        onToggleUserStories={toggleSwitch}
        showLanguageFilter
        showHeroFilter={true}
        showDurationFilter
        showUserStoriesToggle={false}
        defaultLanguage={i18n.language}
        showToneFilter={true}
        showThemeFilter={true}
        showMoralValueFilter={true}
        showEducationalElementFilter={true}
      />
      {loadingStories ?
        <div className="full-center-page"><Loading size={300}></Loading></div>
        : (
          <div className="stories-list">
            {filteredStories.length === 0 ?
              <Link to="/create-story">
                <button type="button" className="submit-btn">
                  {t('create_your_story')}
                </button>
              </Link>
              :
              (filteredStories.map((story) => (
                <div key={story.uuid}>
                  <StoryCard story={story} showPublicTag={true}/>
                </div>
              )))
            }
          </div>)
      }
    </AppLayout>
  );
};
