import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllStories } from '../api/stories';
import { StoryCard } from '../components/StoryCard/StoryCard';
import { AppLayout } from '../components/AppLayout/AppLayout';
import { FilterPanel } from '../components/FilterPanel/FilterPanel';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import '../styles/HomePage.css';
import { Loading } from '../components/Loading/Loading';
import { supabase } from '../api/supabase';

type Hero = {
  uuid: string;
  name: string;
};

export const HomePage: React.FC = () => {
  const i18nLanguage = i18n.language.length > 2 ? i18n.language.substring(0, 2) : i18n.language
  const [stories, setStories] = useState<any[]>([]);
  const [filteredStories, setFilteredStories] = useState<any[]>([]);
  const [loadingStories, setLoadingStories] = useState<any>(true);
  const [heroes, setHeroes] = useState<Hero[]>([]);
  const [selectedHero, setSelectedHero] = useState<Hero | null>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(i18nLanguage);
  const [selectedTone, setSelectedTone] = useState<string | null>();
  const [selectedTheme, setSelectedTheme] = useState<string | null>();
  const [selectedMoralValue, setSelectedMoralValue] = useState<string | null>();
  const [selectedEducationalElement, setSelectedEducationalElement] = useState<string | null>();
  const [maxDuration, setMaxDuration] = useState<number | undefined>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showAdvancedFilters, setShowAdvancedFilters] = useState<boolean>(false);
  const { t } = useTranslation();
  const [isOnlyUserStories, setIsOn] = useState(false);

  const toggleSwitch = () => setIsOn(!isOnlyUserStories);
  const toggleAdvancedFilters = () => setShowAdvancedFilters(!showAdvancedFilters);

  useEffect(() => {
    const fetchStories = async () => {
      setLoadingStories(true)
      const fetchedStories = await getAllStories();
      setStories(fetchedStories);
      setFilteredStories(fetchedStories);

      // Extract heroes from stories
      const heroesList: Hero[] = [];
      fetchedStories.forEach((story: any) => {
        if (story.heroes) {
          story.heroes.forEach((hero: Hero) => {
            if (!heroesList.find((h) => h.uuid === hero.uuid)) {
              heroesList.push(hero);
            }
          });
        }
      });
      setHeroes(heroesList);
      setLoadingStories(false)
    };
    fetchStories();

    const storyListener = supabase
      .channel('stories') // Canal realtime
      .on('postgres_changes', { event: '*', schema: 'public', table: 'stories' }, payload => {
        // Gérer les événements de modification des stories (INSERT, UPDATE, DELETE)
        if (payload.eventType === 'INSERT') {
          setStories((prevStories) => {
            const isStoryExists = prevStories.some(story => story.uuid === payload.new.uuid);
            if (!isStoryExists) {
              return [...prevStories, payload.new]; // Ajouter uniquement si la story n'existe pas
            }
            return prevStories; // Sinon, on ne fait rien
          });
        } else if (payload.eventType === 'UPDATE') {
          setStories((prevStories) => 
            prevStories.map(story => story.uuid === payload.new.uuid ? payload.new : story)
          ); // Mettre à jour la story modifiée
        } else if (payload.eventType === 'DELETE') {
          setStories((prevStories) => prevStories.filter(story => story.uuid !== payload.old.uuid)); // Supprimer la story supprimée
        }
        setStories((prevStories) => {
          const sortedStories = [...prevStories].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
          return sortedStories;
        });
    }).subscribe();
    
    return () => {
      supabase.removeChannel(storyListener);
    };
  }, []);

  const handleLanguageChange = (selectedOption: any) => {
    setSelectedLanguage(selectedOption?.value || null);
  };

  const handleToneChange = (selectedOption: any) => {
    setSelectedTone(selectedOption?.value || null);
  };

  const handleThemeChange = (selectedOption: any) => {
    setSelectedTheme(selectedOption?.value || null);
  };

  const handleMoralValueChange = (selectedOption: any) => {
    setSelectedMoralValue(selectedOption?.value || null);
  };

  const handleEducationalElementChange = (selectedOption: any) => {
    setSelectedEducationalElement(selectedOption?.value || null);
  };

  const handleHeroChange = (selectedOption: any) => {
    const selected = heroes.find((h) => h.uuid === selectedOption?.value);
    setSelectedHero(selected || null);
  };

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? parseInt(e.target.value, 10) : undefined;
    setMaxDuration(value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    let filtered = [...stories];

    if (searchTerm) {
      filtered = filtered.filter((story) =>
        story.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        story.summary.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    if (selectedLanguage) {
      filtered = filtered.filter((story) => story.language === selectedLanguage);
    }

    if (selectedTone) {
      filtered = filtered.filter((story) => story.tone === selectedTone);
    }

    if (selectedTheme) {
      filtered = filtered.filter((story) => story.themes === selectedTheme);
    }

    if (selectedMoralValue) {
      filtered = filtered.filter((story) => story.moral_values?.some((moralValue: string) => moralValue === selectedMoralValue));
    }

    if (selectedEducationalElement) {
      filtered = filtered.filter((story) => story.educational_elements?.some((educationalElement: string) => educationalElement === selectedEducationalElement));
    }

    if (selectedHero) {
      filtered = filtered.filter((story) =>
        story.heroes?.some((hero: Hero) => hero.uuid === selectedHero.uuid)
      );
    }

    if (maxDuration !== undefined) {
      filtered = filtered.filter((story) => {
        const totalDuration = story.chapters?.reduce((acc: number, chapter: any) => acc + chapter.duration, 0);
        return totalDuration <= maxDuration;
      });
    }

    setFilteredStories(filtered);
  }, [searchTerm, selectedLanguage, selectedTone, selectedTheme, selectedMoralValue, selectedEducationalElement, selectedHero, maxDuration, stories]);

  const heroOptions = heroes.map((hero) => ({ value: hero.uuid, label: hero.name }));

  return (
    <AppLayout>
      <div className="search-bar">
        <div className="div-search">
          <input
            type="text"
            placeholder={t('search_placeholder')}
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input"
          />
          <div onClick={toggleAdvancedFilters} className="advanced-search-link">
            {showAdvancedFilters ? t('hide_advanced_search') : t('show_advanced_search')}
          </div>
        </div>
        <div className="div-filters">
          {showAdvancedFilters && (
            <FilterPanel
              heroes={heroOptions}
              maxDuration={maxDuration}
              isOnlyUserStories={isOnlyUserStories}
              onLanguageChange={handleLanguageChange}
              onToneChange={handleToneChange}
              onThemeChange={handleThemeChange}
              onMoralValueChange={handleMoralValueChange}
              onEducationalElementChange={handleEducationalElementChange}
              onHeroChange={handleHeroChange}
              onDurationChange={handleDurationChange}
              onToggleUserStories={toggleSwitch}
              showLanguageFilter
              showHeroFilter={false}
              showDurationFilter
              showUserStoriesToggle={false}
              defaultLanguage={i18nLanguage}
              showToneFilter={true}
              showThemeFilter={true}
              showMoralValueFilter={true}
              showEducationalElementFilter={true}
            />
          )}
        </div>
      </div>
      
      {loadingStories ?
        <div className="full-center-page"><Loading size={300}></Loading></div>
        :
        <div className="stories-list">
          {filteredStories.length === 0 ? (
            <Link to="/create-story">
              <button type="button" className="submit-btn">
                {t('create_your_story')}
              </button>
            </Link>
          ) : (
            filteredStories.map((story) => (
              <div key={story.uuid}>
                <StoryCard story={story} showPublicTag={false} />
              </div>
            ))
          )
          }
        </div>
      }
    </AppLayout>
  );
};
