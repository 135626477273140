import React from 'react';
import Select from 'react-select';
import i18n from '../../i18n'
import { useTranslation } from 'react-i18next';

const languageOptions = [
  { value: 'fr', label: <>🇫🇷 Français</> },
  { value: 'en', label: <>🇬🇧 English</> },
  { value: 'es', label: <>🇪🇸 Español</> },
  { value: 'pt', label: <>🇵🇹 Português</> },
  { value: 'de', label: <>🇩🇪 Deutschland</> },
  { value: 'it', label: <>🇮🇹 Italian</> },
  { value: 'ja', label: <>🇯🇵 日本語</> },
  { value: 'cn', label: <>🇨🇳 中国人</> }
];

export const LanguageSwitcher: React.FC = () => {
  const { t } = useTranslation()
  const handleChange = (selectedOption: any) => {
    const languageCode = selectedOption.value;
    i18n.changeLanguage(languageCode);
  };
  const i18nLanguage = i18n.language.length > 2 ? i18n.language.substring(0, 2) : i18n.language

  return (
    <Select
      options={languageOptions}
      onChange={handleChange}
      placeholder={t('select_language')}
      defaultValue={languageOptions.find(option => option.value === i18nLanguage)}
      className="language-select"
      classNamePrefix="language-select"
      menuPlacement="top"
    />
  );
};
