import React, { useState, useEffect, useTransition } from 'react';
import { Link } from 'react-router-dom';
import './StoryCard.css';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext/AuthContext';

const storyIllustrationDefault = '../../img/logo.webp';

export const StoryCard: React.FC<{ story: any, showPublicTag:boolean }> = ({ story, showPublicTag }) => {
  const [duration, setDuration] = useState(0);
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation()

  const truncateSummary = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  useEffect(() => {
    async function computeDuration(chapters: any[]) {
      if (!Array.isArray(chapters)) return;

      let totalDuration = 0;
      for (let i = 0; i < chapters?.length; i++) {
        totalDuration += chapters[i].duration;
      }
      setDuration(totalDuration);
    }
    computeDuration(story?.chapters);
  }, [story]);

  return (
    <div className="story-card">
      <img src={story.illustration_file_url ?? storyIllustrationDefault} alt={`Illustration de l'histoire "${story.title}"`} className="story-illustration" />
      <div className="story-card-content">
        <Link to={`/story/${story.uuid}`} className="no-style-a">
          <h2 className="story-card-title">{story.title}</h2>
        </Link>
        <p className="story-card-summary">
          {story.summary && truncateSummary(story.summary, 140)}
        </p>
        <div className="story-card-tags">
          {story.heroes?.map((hero: { name: string }, index: number) => <span key={index} className="story-card-tag">👤 {hero.name}</span>)}
        </div>
        <div className="story-card-tags">
            <span className="story-card-tag">⏰ {duration} minutes</span>
        </div>
        {showPublicTag &&
          <div className="story-card-tags">
            <span className="story-card-tag">{story.is_public ? t('public_story') : t('private_story')}</span>
          </div>
        }
        <Link to={`/story/${story.uuid}`} className="details-button">
          {isAuthenticated ? t('read_or_listen') : t('read')}
        </Link>
      </div>  
    </div>
  );
};
