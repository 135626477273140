import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppLayout } from '../components/AppLayout/AppLayout';
import { auth } from '../api/auth-users';
import { useTranslation } from 'react-i18next';
import '../styles/AuthPage.css';
import { useAuth } from '../components/AuthContext/AuthContext';

export const AuthPage: React.FC = () => {
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      const response = await auth(email, password, isLogin ? 'login' : 'signup');

      if (response.token !== undefined) {
        localStorage.setItem("token", response.token);
        localStorage.setItem("refresh_token", response.refresh_token);

        setMessage('Login successful! Redirecting...');

        setIsAuthenticated(true)
        window.location.href = '/';
      } else {
        setMessage(response.data.message);
      }
    } catch (error: any) {
      setMessage(error.response?.data?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppLayout>
      <div className="story-details-container">
        <div
          className="story-banner"
          style={{
            backgroundImage: `url('../assets/background.webp')`,
          }}
        >
          <h1>{isLogin ? t('login') : t('signup')}</h1>
        </div>
        <div className="auth-form">
          <form onSubmit={handleSubmit}>
            <div className="auth-input">
              <label>{t('email')}:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="auth-input">
              <label>{t('password')}:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <p>{message}</p>
            <div className="auth-btn">
              <button type="submit" className="submit-btn" disabled={loading}>
                {loading ? t('loading') : isLogin ? t('login') : t('signup')}
              </button>
            </div>
          </form>
        
          <div className="auth-btn-second">
            <button onClick={() => setIsLogin(!isLogin)} className="btn" disabled={loading}>
              {isLogin ? t('switch_to_signup') : t('switch_to_login')}
            </button>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};
