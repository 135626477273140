import React from 'react';
import { SideMenu } from '../SideMenu/SideMenu';
import './AppLayout.css';

interface AppLayoutProps {
    children: React.ReactNode; // Type correct pour les enfants
}
  
export const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
    return (
      <div className="app-container">
        <SideMenu />
        <div className="content-container">{children}</div>
      </div>
    );
};