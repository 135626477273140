// src/i18n.ts
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import enTranslation from "./locales/en/translation.json";
import frTranslation from "./locales/fr/translation.json";
import esTranslation from "./locales/es/translation.json";
import itTranslation from "./locales/it/translation.json";
import deTranslation from "./locales/de/translation.json";
import ptTranslation from "./locales/pt/translation.json";
import cnTranslation from "./locales/cn/translation.json";
import jaTranslation from "./locales/ja/translation.json";

// Configuration de i18next
i18n
  .use(LanguageDetector) // Détecte automatiquement la langue de l'utilisateur
  .use(initReactI18next) // intègre i18n avec React
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      fr: {
        translation: frTranslation,
      },
      es: {
        translation: esTranslation,
      },
      de: {
        translation: deTranslation,
      },
      pt: {
        translation: ptTranslation,
      },
      it: {
        translation: itTranslation,
      },
      cn: {
        translation: cnTranslation,
      },
      ja: {
        translation: jaTranslation,
      },
    },
    fallbackLng: "fr", // Langue par défaut si aucune n'est détectée
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["queryString", "cookie", "localStorage", "navigator", "htmlTag"],
      caches: ["localStorage", "cookie"],
    },
  });

export default i18n;
