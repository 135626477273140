import React from 'react';
import './Loading.css';
import { useTranslation } from 'react-i18next';

type LoadingProps = {
  size?: number;
  withText?: boolean;
  imageUrl?: string;
};

export const Loading: React.FC<LoadingProps> = ({ size = 200, withText = true, imageUrl }) => {
    const { t } = useTranslation()
    return (
        <div className="loading-container" style={{
            width: size,
            height: size,
        }}>
            {withText &&
                <div className="loading-container-text">{t('loading')}</div>
            }
            <div
                className="loading-container-img"
                style={{
                    backgroundImage: `url(${imageUrl || '/assets/loading.webp'})`,
                    display: 'flex',
                    flexDirection: 'column',
                    width: size,
                    height: size,
                }}
            />
        </div>
    );
};
